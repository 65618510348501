<Base>
    <svelte:fragment slot="main">
    {#if inputDocument} 
        <Card class="mb-1">
            <CardHeader>
                <CardTitle>
                    Eingabe
                </CardTitle>                
            </CardHeader>
            <CardBody>
                {JSON.stringify(inputDocument, "", 4)}
            </CardBody>
        </Card>    
    {/if}
    {#if outputDocument}
        <Card class="mb-1">
            <CardHeader>
                <CardTitle>
                    Ausgabe
                </CardTitle>                
            </CardHeader>
            <CardBody>
                {JSON.stringify(outputDocument, "", 4)}
            </CardBody>
        </Card>  
    {/if}
    </svelte:fragment>
</Base>
<script>

    import Base from "../../Base.svelte";    

    import { 
        Card, 
        CardBody, 
        CardHeader, 
        CardTitle
    } from '@sveltestrap/sveltestrap';

    export let inputDocument = null;
    export let outputDocument = null;

</script>